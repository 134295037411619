import axios from 'axios';

const apiUrl = process.env.REACT_APP_PEA_API_URL;

export const patientCardApi = {
  uploadToPea(idClient, idPatient, data) {
    return axios
      .put(`${apiUrl}/${idClient}/${idPatient}.jwe`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (error.response && error.response.data) || 'Something went wrong';
      });
  },
};
