import {
  SET_IS_PENDING,
  SAVE_FORM_DATA,
  SAVE_CONFIRM_FORM,
  SET_PATIENT_ID,
  SET_CARD_SCANNER_DATA,
  SET_INSURANCE_LIST,
  SET_QUESTIONS_LIST,
  SET_PDF,
  SET_JSONS,
} from '../actions/patientInfoActions';

import { countriesOptions } from '../../common/countries';

let initialState = {
  isPending: false,
  savedFormData: {
    photo: null,
    patientInfo: {
      salutation: null,
      firstName: '',
      lastName: '',
      dateOfBirth: null,
      gender: null,
      title: '',
      insurance: null,
      insuranceCustom: '',
      insuranceStreet: '',
      insuranceNr: '',
      insuranceZipCode: '',
      insuranceVillage: '',
      insuranceNumber: '',
      insuranceCountry: countriesOptions[0],
      // AHVnumber: '',
      invoiceTo: 'me',
      invoiceToType: null,
    },
    invoice: {
      organisation: '',
      street: '',
      nr: '',
      zipCode: '',
      village: '',
      telephone: '',
      mobilePhone: '',
      email: '',
      // maritalStatus: null,
      salutation: null,
      title: '',
      firstName: '',
      lastName: '',
      dateOfBirth: null,
      country: countriesOptions[0],
      AHVnumber: '',
    },
    contacts: {
      organisation: '',
      street: '',
      nr: '',
      zipCode: '',
      village: '',
      telephone: '',
      mobilePhone: '',
      email: '',
      maritalStatus: null,
      representativeCheck: false,
      country: countriesOptions[0],
      AHVnumber: '',
    },
    representative: {
      salutation: null,
      title: '',
      firstName: '',
      lastName: '',
      street: '',
      nr: '',
      zipCode: '',
      village: '',
      country: countriesOptions[0],
    },
  },
  questions: null,
  declaration: null,
  sign: null,
  isAgree: false,
  patientId: null,
  patientJsonFromRequest: null,
  insuranceList: null,
  questionsList: null,
  pdf: null,
  fhirJsons: null,
};

const patientInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_PENDING:
      return {
        ...state,
        isPending: action.payload,
      };
    case SAVE_FORM_DATA:
      return {
        ...state,
        savedFormData: action.payload,
      };
    case SET_PATIENT_ID:
      return {
        ...state,
        patientId: action.payload,
      };

    case SAVE_CONFIRM_FORM:
      return {
        ...state,
        questions: action.payload.questionnaire,
        sign: action.payload.sign,
        declaration: action.payload.declaration,
        isAgree: action.payload.isAgree,
      };
    case SET_INSURANCE_LIST:
      return {
        ...state,
        insuranceList: action.payload,
      };
    case SET_CARD_SCANNER_DATA:
      const insuranceScanned = action.payload.nameOfInsutance
        ? state.insuranceList.find((item) =>
            item.resource.name.toLowerCase().includes(action.payload.nameOfInsutance.toLowerCase()),
          )
        : null;
      return {
        ...state,
        savedFormData: {
          ...state.savedFormData,
          patientInfo: {
            ...state.savedFormData.patientInfo,
            firstName: action.payload.name || '',
            lastName: action.payload.surname || '',
            insuranceNumber: action.payload.insuranceNumber || '',
            AHVnumber: action.payload.ahvNumber || '',
            dateOfBirth: action.payload.dateOfBirth || '',
            insurance: insuranceScanned
              ? {
                  value: insuranceScanned.resource.name,
                  label: insuranceScanned.resource.name,
                  id: insuranceScanned.resource.id,
                }
              : null,
          },
        },
      };
    case SET_QUESTIONS_LIST:
      return {
        ...state,
        questionsList: action.payload,
      };
    case SET_PDF:
      return {
        ...state,
        pdf: action.payload,
      };
    case SET_JSONS:
      return {
        ...state,
        fhirJsons: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default patientInfo;
