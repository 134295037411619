import React from 'react';
import s from './notFound.module.scss';

const NotFound = () => {
  return (
    <div className={s.notFound}>
      <h1>404 Nicht gefunden</h1>
      <p>Ooops. Diese Seite existiert nicht.</p>
    </div>
  );
};

export default NotFound;
