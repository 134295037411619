import moment from 'moment';
import { patientCardApi } from '../../api/axiosPatientApi';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import * as jose from 'jose';

export const SET_IS_PENDING = 'SET_IS_PENDING';
export const SAVE_FORM_DATA = 'SAVE_FORM_DATA';
export const SAVE_CONFIRM_FORM = 'SAVE_CONFIRM_FORM';
export const SET_PATIENT_ID = 'SET_PATIENT_ID';
export const SET_PATIENT_JSON = 'SET_PATIENT_JSON';
export const SET_INSURANCE_LIST = 'SET_INSURANCE_LIST';
export const SET_CARD_SCANNER_DATA = 'SET_CARD_SCANNER_DATA';
export const SET_QUESTIONS_LIST = 'SET_QUESTIONS_LIST';
export const SET_PDF = 'SET_PDF';
export const SET_JSONS = 'SET_JSONS';

export const setPending = (payload) => ({ type: SET_IS_PENDING, payload });
export const saveFormData = (payload) => ({ type: SAVE_FORM_DATA, payload });
export const savePatientId = (payload) => ({ type: SET_PATIENT_ID, payload });
export const savePatientJsonFromRequest = (payload) => ({ type: SET_PATIENT_JSON, payload });
export const saveConfirmForm = (payload) => ({ type: SAVE_CONFIRM_FORM, payload });
export const setInsuranceList = (payload) => ({ type: SET_INSURANCE_LIST, payload });
export const setDataFromCardScanner = (payload) => ({ type: SET_CARD_SCANNER_DATA, payload });
export const setQuestionsList = (payload) => ({ type: SET_QUESTIONS_LIST, payload });
export const setPdf = (payload) => ({ type: SET_PDF, payload });
export const setJsons = (payload) => ({ type: SET_JSONS, payload });

//get insurance list
// export const getInsuranceList = () => async (dispatch) => {
//   try {
//     let response = await patientCardApi.getInsuranceList();
//     if (response.status === 200) {
//       dispatch(setInsuranceList(response.data.entry));
//     } else throw new Error(response.error || 'Something wrong');
//   } catch (err) {
//     console.log(err);
//     // dispatch(setSomeError(err.message));
//   }
// };

// export const sendAllForm = () => async (dispatch, getState) => {
//   try {
//     const jsonForm = {
//
//     };
//
//     const generateUuid = async () => {
//       try {
//         const uid = uuidv4();
//         const isOccupied = await client.getFileContents(`/result/${uid}.json`, { format: 'text' });
//         if (isOccupied) {
//           return generateUuid();
//         }
//         return uid;
//       } catch (e) {
//         return generateUuid();
//       }
//     };
//     await client.putFileContents(`/result/${await generateUuid()}.json`, encryptedForm, {
//       overwrite: false,
//     });
//   } catch (error) {
//   } finally {
//   }
// };

// export const createNewInsurance = data => async dispatch => {
//   try {
//     let response = await patientCardApi.createOrganisation(data);
//     if (response.status === 200) {
//       console.log(response);
//       // dispatch(setChangedSuccessfully(true));
//     } else throw new Error(response.error || 'Something wrong');
//   } catch (err) {
//     console.log(err);
//     // dispatch(setSomeError(err.message));
//   }
// };

export const submitAllForm = (blobPdf) => async (dispatch, getState) => {
  try {
    dispatch(setPending(true));

    const state = getState();

    const { patientInfo, invoice, contacts, representative } = state.patientInfo.savedFormData;
    const { configId } = state.settings;

    const patientId = uuidv4();

    const patientName = {
      prefix: [patientInfo.salutation && patientInfo.salutation.value],
      text: patientInfo.firstName + ' ' + patientInfo.lastName,
      given: [patientInfo.firstName],
      family: patientInfo.lastName,
    };
    if (patientInfo.title) patientName.suffix = [patientInfo.title];

    const dateOfBirth =
      patientInfo.dateOfBirth && moment(new Date(patientInfo.dateOfBirth)).format('YYYY-MM-DD');

    const adressOfPacient = {
      line: [contacts.street + ' ' + contacts.nr], // Street name, number, direction & P.O. Box etc.
      city: contacts.village, // Name of city, town etc.
      postalCode: contacts.zipCode, // Postal code for area
      country: contacts.country.value,
    };

    const mobilPhoneInvoice = {
      system: 'phone', // C? phone | fax | email | pager | url | sms | other
      value: invoice.mobilePhone, // The actual contact point details
      use: 'mobile', // home | work | temp | old | mobile - purpose of this contact point
    };
    const homePhoneInvoice = {
      system: 'phone', // C? phone | fax | email | pager | url | sms | other
      value: invoice.telephone, // The actual contact point details
      use: 'home', // home | work | temp | old | mobile - purpose of this contact point
    };
    const emailPhoneInvoice = {
      system: 'email', // C? phone | fax | email | pager | url | sms | other
      value: invoice.email, // The actual contact point details
      use: 'home', // home | work | temp | old | mobile - purpose of this contact point
    };
    const mobilPhoneContacts = {
      system: 'phone', // C? phone | fax | email | pager | url | sms | other
      value: contacts.mobilePhone, // The actual contact point details
      use: 'mobile', // home | work | temp | old | mobile - purpose of this contact point
      // rank: 1,
    };
    const homePhoneContacts = {
      system: 'phone', // C? phone | fax | email | pager | url | sms | other
      value: contacts.telephone, // The actual contact point details
      use: 'home', // home | work | temp | old | mobile - purpose of this contact point
      // rank: 2,
    };
    const emailPhoneContacts = {
      system: 'email', // C? phone | fax | email | pager | url | sms | other
      value: contacts.email, // The actual contact point details
      use: 'home', // home | work | temp | old | mobile - purpose of this contact point
    };

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const photoFile = state.patientInfo.savedFormData.photo;

    const photo = {
      data: '',
      contentType: '',
    };

    if (photoFile) {
      const base64File = await toBase64(photoFile);

      const photoString = base64File && base64File.split(';base64,');
      const dataType = photoString && photoString[0].split('data:');

      photo.contentType = dataType ? dataType[1] : null;
      photo.data = photoString ? photoString[1] : null;
    }

    const uuid = configId;

    const patientResource = {
      resourceType: 'Patient',
      id: patientId,
      name: [patientName],
      birthDate: dateOfBirth,
      gender: patientInfo.gender && patientInfo.gender.value,
      address: [adressOfPacient],
      telecom: [emailPhoneContacts],
    };

    if (contacts.AHVnumber)
      patientResource.identifier = [{ system: 'urn:oid:2.16.756.5.32', value: contacts.AHVnumber }];

    if (photo['data']) {
      patientResource['photo'] = [photo];
    }

    if (contacts.telephone) patientResource.telecom.push(homePhoneContacts);
    if (contacts.mobilePhone) patientResource.telecom.push(mobilPhoneContacts);

    //template for result JSON
    const resultJsonObj = {
      version: 1,
      id: patientId,
      sid: uuid,
      creationTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS UTC'),
      patient: patientResource,
      coverage: {},
      // relatedPersons: [],
      // organizations: [],
      // invoice: {
      //   invoiceTo: patientInfo.invoiceTo,
      // },
      // representative: {
      //   active: false,
      // },
      attachments: [],
    };

    if (patientInfo.invoiceTo === 'different') {
      // let invoiceRecource = null;
      if (patientInfo.invoiceToType === 'person') {
        const personName = {
          prefix: [invoice.salutation && invoice.salutation.value],
          text: invoice.firstName + ' ' + invoice.lastName,
          given: [invoice.firstName],
          family: invoice.lastName,
        };
        if (invoice.title) personName.suffix = [invoice.title];

        const dateOfBirth = invoice.dateOfBirth && moment(new Date(invoice.dateOfBirth)).format('YYYY-MM-DD');

        const relatedPerson = {
          resourceType: 'RelatedPerson',
          id: 'BP',
          patient: {
            reference: 'Patient/' + patientId,
          },
          relationship: [
            {
              coding: [
                {
                  system: 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
                  code: 'BP',
                },
              ],
            },
          ],
          name: [personName],
          birthDate: dateOfBirth,
          address: [
            {
              line: [invoice.street + ' ' + invoice.nr],
              city: invoice.village,
              postalCode: invoice.zipCode,
              country: invoice.country.value,
            },
          ],
          telecom: [emailPhoneInvoice],
        };

        if (invoice.AHVnumber)
          relatedPerson.identifier = [{ system: 'urn:oid:2.16.756.5.32', value: invoice.AHVnumber }];

        if (invoice.telephone) relatedPerson.telecom.push(homePhoneInvoice);
        if (invoice.mobilePhone) relatedPerson.telecom.push(mobilPhoneInvoice);

        if (!resultJsonObj.relatedPersons) resultJsonObj.relatedPersons = [];
        resultJsonObj.relatedPersons.push(relatedPerson);
      }
      if (patientInfo.invoiceToType === 'organisation') {
        const organisation = {
          resourceType: 'Organization',
          type: [
            {
              coding: [
                {
                  system: 'http://terminology.hl7.org/CodeSystem/organization-type',
                  code: 'PAY',
                },
              ],
            },
          ],

          name: invoice.organisation,
          address: [
            {
              line: [invoice.street + ' ' + invoice.nr],
              city: invoice.village,
              postalCode: invoice.zipCode,
              country: invoice.country.value,
            },
          ],
          telecom: [emailPhoneInvoice],
        };

        if (invoice.telephone) organisation.telecom.push(homePhoneInvoice);
        if (invoice.mobilePhone) organisation.telecom.push(mobilPhoneInvoice);

        if (!resultJsonObj.organizations) resultJsonObj.organizations = [];
        resultJsonObj.organizations.push(organisation);
      }
    }

    if (contacts.representativeCheck) {
      if (!resultJsonObj.relatedPersons) resultJsonObj.relatedPersons = [];
      const personName = {
        prefix: [representative.salutation && representative.salutation.value],
        text: representative.firstName + ' ' + representative.lastName,
        given: [representative.firstName],
        family: representative.lastName,
      };

      if (representative.title) personName.suffix = [representative.title];

      const representativePerson = {
        resourceType: 'RelatedPerson',
        id: 'GUARD',
        patient: {
          reference: 'Patient/' + patientId,
        },
        relationship: [
          {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
                code: 'GUARD',
              },
            ],
          },
        ],
        name: [personName],
        address: [
          {
            line: [representative.street + ' ' + representative.nr], // Street name, number, direction & P.O. Box etc.
            city: representative.village, // Name of city, town etc.
            postalCode: representative.zipCode, // Postal code for area
            country: representative.country.value,
          },
        ],
      };
      resultJsonObj.relatedPersons.push(representativePerson);
    }
    //todo ???
    // const insurnceData = async () => {
    //   try {
    //     if (patientInfo.insurance && patientInfo.insurance.value !== 'other') {
    //       const insuranceReference = {
    //         reference: `Organization/${patientInfo.insurance.id}`,
    //       };
    //       if (Patient.contact) {
    //         return [
    //           {
    //             ...Patient.contact[0],
    //             organization: insuranceReference,
    //             relationship: [insuranceCoding],
    //           },
    //         ];
    //       } else return [{ organization: insuranceReference, relationship: [insuranceCoding] }];
    //     } else if (patientInfo.insurance && patientInfo.insurance.value === 'other') {
    //       //create new Organisation (insurance company)
    //       const name = patientInfo.insuranceCustom;
    //       const street = patientInfo.insuranceStreet;
    //       const nr = patientInfo.insuranceNr;
    //       const zip = patientInfo.insuranceZipCode;
    //       const village = patientInfo.insuranceVillage;

    //       const organisationAdress = {
    //         use: 'work',
    //         line: [`${street} ${nr}`], // Street name, number, direction & P.O. Box etc.
    //         city: village, // Name of city, town etc.
    //         postalCode: zip, // Postal code for area
    //       };

    //       let responseNewOrganisation = await patientCardApi.createOrganisation(name, organisationAdress);

    //       if (responseNewOrganisation.status === 201) {
    //         if (Patient.contact) {
    //           return [
    //             {
    //               ...Patient.contact[0],
    //               organization: {
    //                 reference: `Organization/${responseNewOrganisation.data.id}`,
    //               },
    //               relationship: [insuranceCoding],
    //             },
    //           ];
    //         } else {
    //           return [
    //             {
    //               organization: {
    //                 reference: `Organization/${responseNewOrganisation.data.id}`,
    //               },
    //               relationship: [insuranceCoding],
    //             },
    //           ];
    //         }
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    // get insurance data
    // await insurnceData().then((data) => {
    //   //todo
    //   Patient.contact = data;
    // });

    if (patientInfo.insurance) {
      const coverage = {
        resourceType: 'Coverage',
        beneficiary: { reference: 'Patient/' + patientId },
        payor: [{ reference: 'Patient/' + patientId }],
      };

      //if Versichertennummer has defined
      if (patientInfo.insuranceNumber)
        coverage.identifier = [
          {
            system: 'urn:oid:2.16.756.5.30.1.123.100.1.1.1',
            value: patientInfo.insuranceNumber,
          },
        ];

      //if not 'other' insurance
      if (patientInfo.insurance.value !== 'other' && patientInfo.insurance.value !== 'none') {
        coverage.policyHolder = {
          identifier: patientInfo.insurance.value,
        };
      }
      //if 'other' insurance
      else if (patientInfo.insurance.value === 'other') {
        coverage.policyHolder = {
          reference: 'Organization/INS',
        };

        const organisation = {
          resourceType: 'Organization',
          type: [
            {
              coding: [
                {
                  system: 'http://terminology.hl7.org/CodeSystem/organization-type',
                  code: 'INS',
                },
              ],
            },
          ],
          name: patientInfo.insuranceCustom,
          address: [
            {
              line: [patientInfo.insuranceStreet + ' ' + patientInfo.insuranceNr],
              city: patientInfo.insuranceVillage,
              postalCode: patientInfo.insuranceZipCode,
              country: patientInfo.insuranceCountry.value,
            },
          ],
        };

        if (!resultJsonObj.organizations) resultJsonObj.organizations = [];
        resultJsonObj.organizations.push(organisation);
      } else if (patientInfo.insurance.value === 'none') {
        delete coverage.policyHolder;
      }

      if (patientInfo.invoiceTo === 'insurance') {
        coverage.payor = coverage.policyHolder;
      } else if (patientInfo.invoiceTo === 'different') {
        if (patientInfo.invoiceToType === 'person') coverage.payor = [{ reference: 'RelatedPerson/BP' }];
        if (patientInfo.invoiceToType === 'organisation')
          coverage.payor = [{ reference: 'Organization/PAY' }];
      } else if (patientInfo.invoiceTo === 'legalRepresentative') {
        coverage.payor = [
          {
            reference: 'RelatedPerson/GUARD',
          },
        ];
      }

      // if (contacts.representativeCheck) {
      //   coverage.payor = [
      //     {
      //       reference: 'RelatedPerson/' + patientId,
      //     },
      //   ];
      // }
      resultJsonObj.coverage = coverage;
    }

    // const response = await patientCardApi.sendPatientCard(Patient);

    // if (response.status === 200) {
    //   dispatch(savePatientId(response.data.id));
    //   dispatch(savePatientJsonFromRequest(response.data));

    //save questions answers

    // if (state.patientInfo.questions && state.patientInfo.questionsList) {
    // const patientId = response.data.id;
    // const questionnaireId = state.patientInfo.questionsList

    const questionsAnswers = state.patientInfo?.questionsList?.map((item, index) => {
      const preparedAnswer = (state.patientInfo.questions && state.patientInfo.questions[item.id]) || null;
      const answer = item.type !== 'checkbox' ? preparedAnswer || '-' : preparedAnswer?.join('; ') || '-';

      // const uid = uuidv4();

      const questions = {
        linkId: index,
        text: item.label,
        type: item.type === 'radio' || item.type === 'checkbox' ? 'choice' : 'text',
      };

      if (item.options && item.options.length > 0) {
        if (item.options.length > 1 && questions.type === 'choice') questions.repeats = true;

        questions.answerOption = item.options.map((option) => ({
          valueCoding: {
            code: option.replace(/ /g, '_'),
            display: option,
          },
        }));
      }

      const answers = {
        linkId: index,
        text: item.label,
      };
      if (item.type === 'text')
        answers.answer = [
          {
            valueString: answer,
          },
        ];
      else if (item.type === 'checkbox') {
        if (preparedAnswer && preparedAnswer.length > 0)
          answers.answer = preparedAnswer.map((option) => ({
            valueCoding: {
              code: option.replace(/ /g, '_'),
              display: option,
            },
          }));
      } else if (item.type === 'radio') {
        if (preparedAnswer)
          answers.answer = [
            {
              valueCoding: {
                code: preparedAnswer.replace(/ /g, '_'),
                display: preparedAnswer,
              },
            },
          ];
      }

      return { questions, answers };
    });

    if (questionsAnswers) {
      const questionnaire = {
        resourceType: 'Questionnaire',
        subject: {
          reference: 'Patient/' + patientId,
        },
        status: 'active',
        item: questionsAnswers.map((item) => item.questions),
      };

      const questionnaireResponse = {
        resourceType: 'QuestionnaireResponse',
        subject: {
          reference: 'Patient/' + patientId,
        },
        status: 'completed',
        item: questionsAnswers.map((item) => item.answers),
      };
      resultJsonObj.questionnaire = questionnaire;
      resultJsonObj.questionnaireResponse = questionnaireResponse;
    }

    if (blobPdf) {
      const base64File = await toBase64(blobPdf);
      const pdfString = base64File && base64File.split(';base64,');
      const dataType = pdfString && pdfString[0].split('data:');
      resultJsonObj.attachments.push({
        title: 'PeaRegistration',
        contentType: dataType ? dataType[1] : null,
        data: pdfString ? pdfString[1] : null,
      });
    }

    ///encryption
    const configForJWK = state.settings.pubKey.jwk;

    // const configForJWK = {
    //   kty: 'EC',
    //   use: 'enc',
    //   crv: 'P-256',
    //   kid: '3f7fe68b-b34e-4a33-8b87-607fe654a124',
    //   x: '_BtooNNWaypnf1sy-F_zihbUhxT1sd_eXU0yq5OThuI',
    //   y: 'fYz2ze4krSJMVEtzumVRoZoJZXDKwlHE5hkVqvln-nA',
    //   iat: 1674219937,
    // };
    // const alg = 'ECDH-ES';
    // const enc = 'A256GCM';

    // console.log(JSON.stringify(configForJWK));
    const ecPublicKey = await jose.importJWK(configForJWK, state.settings.pubKey.alg);
    // const ecPublicKey = await jose.importJWK(configForJWK, alg);

    const json = JSON.stringify(resultJsonObj);

    const jwe = await new jose.CompactEncrypt(new TextEncoder().encode(json))
      .setProtectedHeader({ alg: state.settings.pubKey.alg, enc: state.settings.pubKey.enc })
      // .setProtectedHeader({ alg, enc })
      .encrypt(ecPublicKey);

    // console.log('----JSON----', json);
    // console.log('--enctyptData---', jwe);

    dispatch(setJsons({ unencrypted: json, encrypted: jwe }));

    // await client.putFileContents(`/result/${patientId}.jwe`, jwe, {
    //   overwrite: false,
    // });

    // await client.putFileContents(`/resultDecrypt/${patientId}.json`, json, {
    //   overwrite: false,
    // });

    ////For test decryption
    // const ecPrivateKey = {
    //   kty: 'EC',
    //   d: '0kQ7frd87gy2LIlFMHoJQfMQtkHdk4rmf1BwdRdXMng',
    //   use: 'enc',
    //   crv: 'P-256',
    //   kid: '3f7fe68b-b34e-4a33-8b87-607fe654a124',
    //   x: '_BtooNNWaypnf1sy-F_zihbUhxT1sd_eXU0yq5OThuI',
    //   y: 'fYz2ze4krSJMVEtzumVRoZoJZXDKwlHE5hkVqvln-nA',
    //   iat: 1674219937,
    // };
    // const ecPublicKeyEncryp = await jose.importJWK(ecPrivateKey, alg);
    // const { plaintext, protectedHeader } = await jose.compactDecrypt(jwe, ecPublicKeyEncryp);
    // console.log('protectedHeader', protectedHeader);
    // console.log('------decoded---------', new TextDecoder().decode(plaintext));
  } catch (err) {
    console.log(err);
    // debugger;
    dispatch(setPending(false));
    toast.error('Error! Please try again later.');
    return err;
  }
};

export const uploadJweToPea = (data, patientId, formData) => async (dispatch) => {
  try {
    let response = await patientCardApi.uploadToPea(data, patientId, formData);
    // console.log(response);
    if (response.status === 200) {
      // dispatch(setChangedSuccessfully(true));
    } else throw new Error(response.error || 'Something wrong');
  } catch (err) {
    console.log(err);
    // dispatch(setSomeError(err.message));
  }
};
