import { createClient } from 'webdav/web';
import { setQuestionsList } from './patientInfoActions';

const webDavURL = process.env.REACT_APP_WEBDAV_URL;

const client = createClient(webDavURL ? `${webDavURL}/rdus/config/` : `https://ee.medelexis.ch/rdus`);

export const SET_LOGO = 'SET_LOGO';
export const SET_MAIN_COLOR = 'SET_MAIN_COLOR';
export const SET_CONFIG_ID = 'SET_CONFIG_ID';
export const SET_PUB_KEY = 'SET_PUB_KEY';
export const SET_PRACTICE_NAME = 'SET_PRACTICE_NAME';

export const setPracticeName = (payload) => ({ type: SET_PRACTICE_NAME, payload });
export const setMainLogo = (payload) => ({ type: SET_LOGO, payload });
export const setMainColor = (payload) => ({ type: SET_MAIN_COLOR, payload });
export const setConfigId = (payload) => ({ type: SET_CONFIG_ID, payload });
export const setPubkey = (payload) => ({ type: SET_PUB_KEY, payload });

export const getConfig = (uuid) => async (dispatch) => {
  try {
    const response = await client.getFileContents(`${uuid}.json`, { format: 'text' });
    const responseToJson = JSON.parse(response);

    document.documentElement.style.setProperty('--baseColor', responseToJson.pcolor);
    dispatch(setConfigId(responseToJson.pid));
    dispatch(setPracticeName(responseToJson.pname));
    dispatch(setPubkey(responseToJson.ppubkey));
    dispatch(setMainLogo(responseToJson.pimg));
    dispatch(setMainColor(responseToJson.pcolor));
    dispatch(setQuestionsList(responseToJson.questionnaire));
    return responseToJson.pid;
    // console.log(response);
  } catch (err) {
    console.log(err);
  }
};
