import {
  SET_LOGO,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_LOGO_ERROR,
  SET_MAIN_COLOR,
  SET_CONFIG_ID,
  SET_PUB_KEY,
  SET_PRACTICE_NAME,
} from '../actions/adminSettings';

let initialState = {
  logo: null,
  color: null,
  configId: null,
  pubKey: null,
  practiceName: null,
};

const adminSettings = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRACTICE_NAME:
      return {
        ...state,
        practiceName: action.payload,
      };
    case SET_LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    case SET_CONFIG_ID:
      return {
        ...state,
        configId: action.payload,
      };
    case SET_PUB_KEY:
      return {
        ...state,
        pubKey: action.payload,
      };
    case SET_MAIN_COLOR:
      return {
        ...state,
        color: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default adminSettings;
